<template>
  <div class="rule">
    <div class="vote-rule">
      <h3 class="title">答题规则</h3>
      <Form
        :model="formValidate"
        :label-colon="true"
        :label-width="140"
        :rules="ruleValidate"
        ref="formValidate"
      >
        <FormItem label="题库分类">
          <div v-for="(item, index) in questionBankList" :key="index">
              <p v-if="item.questionBankId == formValidate.questionBankId">{{item.questionBankType}}</p>
          </div>
          <div v-if="!formValidate.questionBankId">无</div>
        </FormItem>
        <FormItem label="实名验证">
          <p>{{formValidate.userVerified == '1' ? '是' : '否'}}</p>
        </FormItem>
        <FormItem label="每局题目数">
          <p>{{formValidate.roundQuestionNumber != '' ? (formValidate.roundQuestionNumber + '条') : '无'}}</p>
        </FormItem>
        <FormItem label="每题限时">
          <p>{{formValidate.questionLimit!='' ?  (formValidate.questionLimit+ '秒') : '无'}}</p>
        </FormItem>
        <FormItem label="参与人数">
          <p>{{formValidate.participantsNumber != '' ? (formValidate.participantsNumber + '人') : '无'}}</p>
        </FormItem>
        <FormItem label="中奖人数">
          <p>{{formValidate.winnersNumber !=''? (formValidate.winnersNumber + '人') : '无'}}</p>
        </FormItem>
        <FormItem label="评奖方式">
          <p>{{formValidate.awardMethod == '1' ? '用时最短排行' : '无'}}</p>
        </FormItem>
        <FormItem label="有效领奖时间">
          <span>{{formValidate.effectiveTime | filterTime}}</span>
          <span style="margin: 0 6px">至</span>
          <span>{{formValidate.expirationTime | filterTime}}</span>
        </FormItem>

        <!-- <FormItem label="周期设置" prop="voteCycle">
          <RadioGroup v-model="formValidate.voteCycle">
            <Radio :label="item.dictKey" v-for="(item,index) in formValidate.voteCycleList" :key="index">{{item.dictValue}}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="【单选】票数设置"
          class="number-vote"
          v-if="formValidate.voteType == '1'"
        >
          <div class="number-vote-setting">
            <span>每个用户可以投</span>
            <FormItem prop="voteMaxAccount">
              <InputNumber
                :min="1"
                v-model="formValidate.voteMaxAccount"
              ></InputNumber>
            </FormItem>
            <span>次</span>
          </div>

          <div class="number-vote-setting">
            <span>可为同一选手投</span>
            <FormItem prop="voteMaxItem">
              <InputNumber
                :min="1"
                v-model="formValidate.voteMaxItem"
              ></InputNumber>
            </FormItem>
            <span>次</span>
          </div>
        </FormItem>
        <FormItem
          label="【多选】票数设置"
          class="number-vote"
          v-else
        >
          <div class="number-vote-setting">
            <span>最少选择</span>
            <FormItem prop="selectMinItem">
              <InputNumber
                :min="1"
                v-model="formValidate.selectMinItem"
              ></InputNumber>
            </FormItem>
            <span>个选项</span>
          </div>
          <div class="number-vote-setting">
            <span>最多选择</span>
            <FormItem prop="selectMaxItem">
              <InputNumber
                :min="1"
                v-model="formValidate.selectMaxItem"
              ></InputNumber>
            </FormItem>
            <span>个选项</span>
          </div>
        </FormItem> -->
      </Form>
    </div>
  </div>
</template>

<script>
import { format } from "@/utils/formatTime";
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      questionBankList: []
    };
  },
  filters: {
    filterTime(val) {
      if(!val) return '无';
      return format(val,'yyyy-MM-dd');
    }
  },
  methods: {
    backStep() {
      this.$emit("nextStep", -1);
    },
    nextStep() {
        this.$emit("nextStep", 1);
    },
  },
  created() {
    this.$get('/voteapi/api/pc/componentQuestion/selectQuestionBank',{
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if(res.code == 200) {
        this.questionBankList = res.dataList;
      }
    })
  }
};
</script>

<style scoped lang='less'>
.rule {
  width: 100%;
  .ivu-form {
    margin: 0 30px;
  }
  .number-vote {
    .number-vote-setting {
      display: flex;
      margin-right: 20px;
      float: left;
    }
  }
  .ivu-input-number {
    margin: 0 10px;
  }
  .page-element {
    .ivu-select {
      width: 30%;
    }
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
</style>
